(<template>
  <page-data-section :class="{'in-progress': payoutsProgress}"
                     class="payouts is-progress-bar">
    <h3 class="payouts__desc-title">{{ $gettext('How does Salita payout?') }}</h3>
    <p>{{ salitaPayoutDescription }}</p>
    <h3 class="payouts__desc-title">{{ $gettext('What is PayoutPartner?') }}</h3>
    <p>{{ payoutPartnerDescription }}</p>
    <ul class="payouts__desc-list desc-list">
      <li v-for="(item, index) in payoutPartnerDescItems"
          :key="index"
          class="desc-list__item">{{ item }}</li>
    </ul>
    <p v-if="defaultPayoutMethod"
       class="payouts__attention"
       v-html="payoutAttentionText"></p>
    <button v-else
            class="sk-btn sk-btn--default payouts__list-btn sk-btn--smaller-text js-add-payout-method"
            @click="openPayoutModal">{{ addPayoutText }}</button>
    <payout-method v-if="defaultPayoutMethod"
                   :payout="defaultPayoutMethod"
                   :is-default-payout="true"
                   class="payouts__default-payout" />
    <template v-if="lockedPayoutMethods.length">
      <p class="payouts__sub-title">{{ $gettext('Locked payout methods') }}</p>
      <payout-method v-for="(payout, index) in lockedPayoutMethods"
                     :key="index"
                     :payout="payout"
                     :is-default-payout="false"
                     @setdefaultpayout="setDefaultPayout"
                     @deletepayout="deletePayout" />
    </template>
  </page-data-section>
</template>)

<script>
  import {mapGetters, mapMutations} from 'vuex';
  import PayoutMethod from '@/components/payouts/Payout';
  import PageDataSection from '@/components/shared_components/page_data/PageDataSection';
  import getProductConfig from '@/modules/productConfig';

  export default {
    asyncData({store}) {
      if (!store.state.payoutMethods || !store.state.payoutMethods.data || !store.state.payoutMethods.data.length) {
        return new Promise((resolve, reject) => {
          store.dispatch('PaymentPayoutStore/getPayouts').then(() => {
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      }
    },
    components: {
      'page-data-section': PageDataSection,
      'payout-method': PayoutMethod
    },
    computed: {
      ...mapGetters('PaymentPayoutStore', [
        'payouts',
        'payoutsProgress'
      ]),
      addPayoutText() { return this.$gettext('Create payout method'); },
      salitaPayoutDescription() {
        return this.$gettext('Salita uses PayoutPartner to process your payouts. Each month Salita sends your payout information to PayoutPartner, and they issue your payout to you.');
      },
      payoutPartnerDescription() {
        return this.$gettext('PayoutPartner helps freelancers get paid whether they have or don\'t have their own sole-proprietorship.');
      },
      payoutPartnerDescItems() {
        return [
          this.$gettext('If you have your own sole-proprietorship, they will create invoices on your behalf automatically.'),
          this.$gettext('If you don\'t have your own sole-proprietorship, they will fetch your tax card and issue salaries to you.')
        ];
      },
      supportFinanceEmail() { return getProductConfig(this.$gettext, 'salita', 'domainData', 'supportFinanceEmail'); },
      payoutAttentionText() {
        const template = this.$gettext('To change your payout method, please contact us at <a class="sk-link sk-link--gray-bg" href="mailto:%{email}">%{email}</a>');
        return this.$gettextInterpolate(template, {email: this.supportFinanceEmail});
      },
      defaultPayoutMethod() { return this.payouts.find((payout) => payout.default); },
      lockedPayoutMethods() {
        return this.payouts.filter((payout) => payout.locked).sort((payout1, payout2) => {
          const moment1 = this.$moment().month(payout1.locked.month).year(payout1.locked.year);
          const moment2 = this.$moment().month(payout2.locked.month).year(payout2.locked.year);
          return moment2.diff(moment1);
        });
      }
    },
    methods: {
      ...mapMutations('PaymentPayoutStore', ['setPayoutProgress']),
      openPayoutModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'payout-info-modal',
          width: 600,
          data: {
            context: this,
            callback: 'fetchPayouts'
          }
        });
      },
      fetchPayouts() {
        this.setPayoutProgress(true);
        this.$store.dispatch('PaymentPayoutStore/getPayouts');
      },
      setDefaultPayout(id, type) {
        this.$store.commit('GlobalStore/startProgress');
        this.$store.dispatch('PaymentPayoutStore/setDefaultPayoutMethod', {id, type}).then(() => {
          this.$store.commit('GlobalStore/stopProgress');
        }).catch(() => {
          this.$store.commit('GlobalStore/stopProgress');
        });
      },
      deletePayout(id, type) {
        this.$store.commit('GlobalStore/startProgress');
        this.$store.dispatch('PaymentPayoutStore/deletePayout', {id, type}).then(() => {
          this.$store.commit('GlobalStore/stopProgress');
          this.$store.commit('ModalStore/removeModal');
        }).catch(() => {
          this.$store.commit('GlobalStore/stopProgress');
        });
      }
    },
    beforeDestroy() {
      this.$store.commit('PaymentPayoutStore/removePayouts');
    }
  };
</script>

<style scoped>
  .payouts {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    padding: 20px;
    background-color: #fff;
  }

  .payouts__sub-title {
    margin-bottom: 20px;
    font-weight: bold;
  }

  .payouts__desc-title {
    margin-top: 20px;
    font-weight: bold;
  }

  .payouts__desc-title:first-child {
    margin-top: 0;
  }

  .payouts__attention {
    margin-top: 20px;
    font-size: 17px;
  }

  .payouts__default-payout {
    margin-top: 20px;
  }

  .payouts__banner {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }

  .desc-list {
    padding-left: 40px;
  }

  .desc-list__item {
    padding-left: 15px;
  }

  .payouts__list-btn {
    width: auto;
    min-width: 100px;
    max-width: 300px;
    margin-top: 10px;
    margin-bottom: 0;
    margin-left: auto;
    padding: 0 25px;
  }
</style>
